<template>
  <div :class="['location', {'draft-location': isDraft}]">
    <h2 class="location__title" v-if="!isDraft">{{ $t('project-location') }}</h2>
    <h2 :class="['location__title', {error: isValidLocation}]" v-else>{{ $t('specify-location-on-the-map') }}</h2>
    <ui-loader v-model="visibleLoader"/>
    <div :class="['location__map', {error: isValidLocation}]" v-if="!visibleLoader">
      <GmapMap
          :center="mapCenterPosition"
          :zoom="10"
          map-type-id="terrain"
          style="height: 100%"
          :options="createMapOptions"
          @click="handleMapClick"
      >
        <GmapMarker
            :key="index"
            v-for="(m, index) in getMapEsg"
            :position="m.position"
            :clickable="true"
            :icon="getMarkerIcon(m)"
        />
      </GmapMap>
    </div>
    <div class="location__info" v-if="!visibleLoader">
      <p class="location__info-city">{{ getEsgId.district?.region?.name}}</p>
      <div class="location__info-finder">
        <p>{{ $t('latitude') }}<span>{{getMapEsg[0]?.position.lat}}</span></p>
        <p>{{ $t('longitude') }}<span>{{getMapEsg[0]?.position.lng}}</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UiLoader from "@/components/ui/UiLoader.vue";

export default {
  name: "EsgLocation",
  components: {UiLoader},

  props: {
    isDraft: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isValidLocation: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      createMapOptions: {
        fullscreenControl: false,
      },
      getMapEsg: [],
      visibleLoader: true,
      mapCenterPosition: {}
    }
  },

  computed: {
    ...mapGetters(['getEsgId']),
  },

  methods: {
    handleMapClick(event) {
      if(this.$route.path === '/add-esg' || this.isEdit) {
        let data = [
          {
            icon: 'Default',
            position: {
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            }
          }
        ]
        this.getMapEsg = data
        this.$emit('sendMapInfo', this.getMapEsg[0].position)
      }
    },

    getMarkerIcon(val) {
      let image = null

      switch (val.icon) {
        case 'Esg':
          image = require('@/assets/svg/mapIcons/esg-blue.png')
          break;
        case 'Default':
          image = require('@/assets/svg/icon-location.svg')
          break;
        default:
          break
      }
      return image
    },
  },

  mounted () {
    if(this.$route.path === '/add-esg') {
      this.getMapEsg = []
      this.mapCenterPosition = {
        lat: 55.755819,
        lng: 37.617644
      }
      this.visibleLoader = false
    }else {
      setTimeout(() => {
        this.getMapEsg = [
          {
            icon: 'Esg',
            position: {
              lat: this.getEsgId.lat,
              lng: this.getEsgId.lng,
            }
          }
        ]
        this.$emit('sendMapInfo', this.getMapEsg[0].position)
        this.mapCenterPosition = this.getMapEsg[0].position
        this.visibleLoader = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.location {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  &.draft-location {
    padding: 40px;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    background: #FFFFFF;

    @media (max-width: 500px) {
      padding: 20px;
    }

    .location__map {
      min-height: 466px;
      height: 100%;
      transition: .3s;
      max-width: 540px;
      width: 100%;

      @media (max-width: 1000px) {
        max-width: 100%;
      }

      &.error {
        border: 1px solid #E21F1F;
      }
    }
  }

  &__title {
    color: #1B1A1F;
    font-family: 'Gotham Pro Medium';
    font-size: 14px;
    font-weight: 400;

    &.error {
      color: #E21F1F;
      animation: 0.25s shake;
    }
  }

  &__map {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #D9D9D9;
    width: 100%;
    min-height: 200px;
    height: 100%;
  }

  @media (max-width: 768px) {
    //padding: 0 12px 30px 12px;
    box-shadow: none;
    background: none;
    border: none;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-city {
      color: #1B1A1F;
      font-family: 'Gotham Pro Medium';
      font-size: 14px;
      font-weight: 400;
    }

    &-finder {
      display: flex;
      align-items: center;
      gap: 30px;

      @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #343432;
        white-space: nowrap;

        span {
          color: #CE2121;
        }
      }
    }
  }

  :deep(.gmnoprint div) {
    display: none !important;
  }
}
</style>
