<template>
  <div class="table-row-esg">
    <div class="table-row-esg__item" :style="{maxWidth: '400px', width: '100%', justifyContent: 'flex-start'}">
      {{ data.title }}
      <ui-tooltip color="grey" :isShow="data.tooltipText">
        <div class="text">
          {{data.tooltipText}}
        </div>
      </ui-tooltip>
    </div>
    <div :class="['table-row-esg__item', {grey: (!measure && !isEdit) && $route.path !== '/add-esg'}]" :style="{maxWidth: '940px', width: '100%', justifyContent: 'flex-start'}">
      <p v-if="!isEdit && measure">{{ measure }}</p>
      <ui-input
          v-if="(this.$route.path === '/add-esg' || getEsgId.status === 'Draft') && isEdit"
          placeholder="ЕИ"
          v-model="measure"
          @input="checkInputVal"
      />
    </div>
    <div :class="['table-row-esg__item', {grey: (!description && !isEdit) && $route.path !== '/add-esg'}]" :style="{maxWidth: '940px', width: '100%', justifyContent: 'flex-start'}">
      <p v-if="!isEdit && description">{{ prettify(description) }}</p>
      <ui-input
          v-if="(this.$route.path === '/add-esg' || getEsgId.status === 'Draft') && isEdit"
          :placeholder="$t('value')"
          v-model="description"
          @keypress="isNumber($event)"
          @input="checkInputVal"
      />
    </div>
    <div class="table-row-esg__item" v-if="($route.path === '/add-esg' || isEditDraft)">
      <img src="@/assets/svg/admin/edit-default.svg" alt="" v-if="!isEdit" @click="showEdit"/>
      <div v-if="isEdit && description" @click="sendInfo" class="table-row-esg__item-save">
        <img src="@/assets/images/check-active.png" alt="" />
        {{ $t('save') }}
      </div>
      <div v-if="isEdit && !description" class="table-row-esg__item-disabled">
        <img src="@/assets/images/check-disable.png" alt=""/>
        {{ $t('save') }}
      </div>
    </div>
  </div>
</template>

<script>
import UiTooltip from "@/components/ui/UiTooltip.vue";
import UiInput from "@/components/ui/UiInput.vue";
import {mapGetters} from "vuex";

export default {
  name: "TableRowEsg",
  components: {UiInput, UiTooltip},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isEditDraft: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selectModel: {name: this.$t('yes'), value: 'Yes'},
      options: [
        {name: this.$t('yes'), value: 'Yes'},
        {name: this.$t('no'), value: 'No'}
      ],
      isEdit: false,
      description: '',
      measure: ''
    }
  },

  methods: {
    sendInfo () {
      if(this.description[this.description.length-1] === ',') {
        this.description = this.description.slice(0, -1)
      }
      this.isEdit = !this.isEdit
      let data = {
        description: +this.description.replaceAll(',', '.'),
        measure: this.measure,
        type: this.data.type,
      }
      this.$emit('getTableInfo', data)
    },

    checkInputVal () {
      if (!this.description) {
        let data = {
          description: '',
          measure: this.measure,
          type: this.data.type,
        }
        this.$emit('getTableInfo', data)
      } else if (!this.measure) {
        let data = {
          description: this.description,
          measure: '',
          type: this.data.type,
        }
        this.$emit('getTableInfo', data)
      }
    },

    showEdit () {
      this.isEdit = !this.isEdit
    },

    isNumber (evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ','];
      const keyPressed = evt.key;
      //eslint-disable-next-line
      const valid = /^\d*\,?(?:\d{1})?$/;
      if (!keysAllowed.includes(keyPressed) || !valid.test(evt.target.value) || evt.target.value.length === 13) {
        evt.preventDefault()
      }
    },

    prettify(num) {
      let n = num?.toString();
      return n?.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ' ');
    },
  },

  computed: {
    ...mapGetters(['getEsgId']),
  },

  mounted() {
    this.$route.path === '/add-esg' ? this.isEdit = true: ''
    this.description = this.data.description?.toString().replace(".", ",");
    this.measure = this.data?.measure
  }
}
</script>

<style lang="scss" scoped>
.table-row-esg {
  display: flex;
  align-items: stretch;
  background-color: #FFFFFF;

  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 18px;
    border: 1px solid #F5F5F5;
    border-top: 1px solid #FFFFFF;
    font-family: "Gotham Pro Regular";
    color: #1B1A1F;
    font-size: 14px;
    font-weight: 400;
    line-height: 120.2%;

    &.grey {
      background: #F5F5F5;
      border-right: 1px solid white;
    }

    img {
      cursor: pointer;
    }

    &-save {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }
    }

    &-disabled {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      color: grey;

      img {
        width: 20px;
        height: 20px;
      }
    }

    ::v-deep .ui-select__inner {
      border: none;
      padding: 0 !important;
    }
    ::v-deep .ui-select__label {
      display: none;
    }

    ::v-deep .ui-select {
      z-index: auto;
    }

    ::v-deep .ui-select__options {
      top: 40px;
    }
  }
}
::v-deep .ui-input {
  z-index: 1;
  input {
    border: none;
    padding: 5px 30px 5px 3px;
    height: auto;
  }
}
.table-row-esg:last-child {
  ::v-deep .tooltip__window {
    bottom: 0;
    top: auto;
  }
}
::v-deep .tooltip__window {
  width: 500px;
  
  @media (max-width: 900px) {
    bottom: 0;
    top: auto;
    width: 300px;
  }
}
</style>
