<template>
  <div class="table-esg">
    <div class="table-esg__header" :style="{borderRadius: isBorderRadius ? '20px 20px 0 0' : 0, paddingRight: $route.path === '/add-esg' || isEdit ? '100px' : '0'}">
      <div class="table-esg__header-item" :style="{maxWidth: '400px', width: '100%', justifyContent: 'flex-start'}">{{ $t('indicators') }}</div>
      <div class="table-esg__header-item" :style="{maxWidth: '470px', width: '100%', justifyContent: 'flex-start'}">{{ $t("indicator's-unit-of-measurement") }}</div>
      <div class="table-esg__header-item" :style="{maxWidth: '400px', width: '100%', justifyContent: 'flex-start'}">{{ $t('indicator-values') }}</div>
    </div>
    <div class="table-esg__main">
      <table-row-esg
          v-for="(item, key) in tableInfo"
          :key="key"
          :data="item"
          :style="{borderRadius: isBorderRadius && key === 3 ? '0 0 20px 20px' : 0}"
          :isEditDraft="isEdit"
          @getTableInfo="sendInfo($event)"
      />
    </div>
  </div>
</template>

<script>
import TableRowEsg from "@/components/template/esg/view-esg/TableRowEsg.vue";
import {mapGetters} from "vuex";

export default {
  name: "TableEsg",
  components: {TableRowEsg},
  props: {
    data: {
      type: Array,
      default: () => []
    },
    isBorderRadius: {
      type: Boolean,
      default: true
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      tableInfo: []
    }
  },

  computed: {
    ...mapGetters(['getEsgId'])
  },

  methods: {
    sendInfo (data) {
      this.$emit('getTableInfo', data)
    }
  },

  mounted () {
    this.tableInfo = [
      {
        id: 1,
        title: this.$t('invest-title'),
        description: this.getEsgId.investmentAmount,
        measure: this.getEsgId.investmentMeasure,
        type: 'invest',
        tooltipText: this.$t('invest-tooltip')
      },
      {
        id: 2,
        title: this.$t('plan-title'),
        description: this.getEsgId.efficiencyPlan,
        measure: this.getEsgId.efficiencyMeasure,
        type: 'plan',
        tooltipText: this.$t('plan-tooltip')
      },
      {
        id: 3,
        title: this.$t('fact-title'),
        description: this.getEsgId.efficiencyFact,
        measure: this.getEsgId.efficiencyMeasure,
        type: 'fact',
        tooltipText: this.$t('fact-tooltip')
      }
    ]
  }
}
</script>

<style lang="scss" scoped>
.table-esg {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 20px;
  box-shadow: 2px 2px 10px 0 rgba(153, 153, 153, 0.20);

  &__header {
    display: flex;
    align-items: center;
    background-color: #E21F1F;

    &-item {
      display: flex;
      color: #FFFFFF;
      font-family: 'Gotham Pro Medium';
      font-size: 14px;
      font-weight: 400;
      padding: 10px 20px;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
  }
}
</style>
