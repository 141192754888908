<template>
  <div class="view-esg-content">
    <ui-loader v-model="visibleLoader" />
    <div class="view-esg-content-body body" v-if="!visibleLoader">
      <div class="body__top">
        <div class="body__top-back"><img src="@/assets/svg/arrows/back.svg" alt="" @click="$router.go(-1)"/>{{ $t('to-the-list-of-projects') }}</div>
        <div class="body__top-action" v-if="$route.path !== '/add-esg' && getEsgId.status !== 'Draft' && (getUser?.role === 'Admin' || getUser?.role === 'Moderator' || getUser?.role === 'Lawyer')">
          <ui-button class="body__top-action-close" v-if="visibleCloseRequest" @click="closeModeratorEsg">{{ $t('close-request') }}</ui-button>
        </div>
      </div>
      <div class="body__content content">
        <div class="content__header">
          <div class="content__header-left">
            <div class="wrap">
              <p>{{ $t('request', {data: $route.path !== '/add-esg' ? getEsgId.id : null}) }}</p>
              <div class="wrap-status" v-if="$route.path !== '/add-esg'">
                <div class="chip" :style="{ backgroundColor: getEsgStatus(getEsgId.status).color}" />
                {{ $t(getEsgStatus(getEsgId.status).text) }}
              </div>
            </div>
            <div class="time" v-if="$route.path !== '/add-esg'">
              <p>{{ getFullDate(getEsgId.createdAt).slice(0,10) }}</p>
              <p>{{ getFullDate(getEsgId.createdAt).slice(11,17) }}</p>
            </div>
          </div>
          <p @click="showDeleteAlert = !showDeleteAlert" v-if="getEsgId.status === 'Draft' && $route.path !== '/add-esg' && isEdit" class="content__header-delete"><img src="@/assets/svg/icon-trash.svg" alt="" />{{ $t('delete-draft') }}</p>
          <div class="content__header-right" v-if="$route.path !== '/add-esg' && (getUser?.role === 'Moderator' || getUser?.role === 'Admin') && getEsgId.status !== 'Draft'">
            <router-link :to="`${ getUser.id === getEsgId.createdBy?.id ? '/profile' : '/user/' + getEsgId.createdBy?.id }`" class="content__header-right-user user" >
              <img class="user__avatar" :src="getUserAvatar(getEsgId.createdBy?.avatar, true)" alt="" />
              <div class="user__info">
                <h2 class="user__info-nickname">{{ getNickname }}</h2>
                <p class="user__info-role">
                  <img :src="getUserRole(getEsgId.createdBy?.role).icon" alt=""/>
                  {{ getUserRole(getEsgId.createdBy?.role).text }}
                  <span v-if="getEsgId.createdBy?.role === 'Government' || getEsgId.createdBy?.role === 'Company'">{{ getUserName( getEsgId.createdBy?.lastName, getEsgId.createdBy?.firstName, getEsgId.createdBy?.middleName) }}</span>
                </p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="content__main">
          <esg-location :isValidLocation="isValidLocation" :data="getEsgId" @sendMapInfo="sendMapInfo($event)" :isDraft="isEdit || $route.path === '/add-esg'" :isEdit="isEdit" />
          <esg-check-types ref="form" v-if="isEdit || $route.path === '/add-esg'" @sendInfo="feelAllTypes($event)"/>
          <description-problems-esg v-else :descriptions="descriptions" @accept="accept" @reject="reject"/>
        </div>
      </div>
      <table-esg @getTableInfo="sendTableInfo($event)" :isEdit="isEdit"/>
      <div :class="['body__footer', {add: $route.path === '/add-esg'}]">
        <div class="body__footer-action">
          <ui-button v-if="$route.path === '/add-esg' || isEdit" :disabled="!savedDraftDisabled" @click="addNewEsg('Draft')">{{ $t('save-draft') }}</ui-button>
        </div>
        <ui-button v-if="getEsgId.status === 'Draft' && !isEdit" @click="isEdit=!isEdit">{{ $t('edit-draft') }}</ui-button>
        <div class="body__footer-action">
          <div class="body__footer-action-backdrop" @click="validateForm" v-if="(shouldDisabled && $route.path === '/add-esg') || (shouldDisabled && getEsgId.status === 'Draft' && isEdit)"></div>
          <ui-button
              v-if="$route.path === '/add-esg' || getEsgId.status === 'Draft'"
              color="error"
              :disabled="shouldDisabled || newEsg.files.length === 0"
              @click="addNewEsg('Moderation')"
          >{{ $t('send') }}</ui-button>
        </div>
      </div>
      <ui-alert v-model="showDeleteAlert">
        <div class="ui-alert__title" :style="{color: 'black'}">{{ $t('do-you-really-want-to-delete') }}</div>
        <div class="ui-alert__action">
          <ui-button @click="deleteEsgItem" color="error">{{ $t('delete') }}</ui-button>
          <ui-button @click="showDeleteAlert = false" color="white">{{ $t('cancel-2') }}</ui-button>
        </div>
      </ui-alert>
    </div>
  </div>
</template>

<script>
import UiButton from "@/components/ui/UiButton.vue";
import {getEsgStatus, getFullDate} from "@/utils/general";
import EsgLocation from "@/components/template/esg/view-esg/Location.vue";
import EsgCheckTypes from "@/components/template/esg/view-esg/CheckTypes.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import DescriptionProblemsEsg from "@/components/template/esg/view-esg/Description.vue";
import UiAlert from "@/components/ui/UiAlert.vue";
import TableEsg from "@/components/template/esg/view-esg/TableEsg.vue";
import {getUserAvatar, getUserRole} from "../../../../utils/user";
import UiLoader from "@/components/ui/UiLoader.vue";

export default {
  name: "ViewEsgBody",
  components: {
    UiLoader,
    TableEsg,
    UiAlert,
    DescriptionProblemsEsg,
    EsgCheckTypes,
    EsgLocation,
    UiButton
  },

  data () {
    return {
      pdfIconColor: false,
      chatIconColor: false,
      showDeleteAlert: false,
      visibleLoader: true,
      newEsg: {
        esgCategoryId: null,
        esgTypeId: null,
        esgSubtypeId: null,
        taxonomyType: '',
        lat: 0,
        lng: 0,
        description: '',
        status: '',
        efficiencyPlan: null,
        efficiencyFact: null,
        investmentAmount: null,
        efficiencyMeasure: null,
        investmentMeasure: null,
        efficiencyFactMeasure: null,
        efficiencyPlanMeasure: null,
        name: '',
        files: []
      },
      descriptions: {
        category: '',
        type: '',
        subtype: '',
        taxonomy: '',
        name: ''
      },
      isEdit: false,
      isValidLocation: false,
      isDisabled: false
    }
  },

  computed: {
    ...mapGetters(['getUser', 'getEsgId', 'getEsgCategories', 'getEsgTypes', 'getEsgSubtypes']),
    visibleCloseRequest() {
      return (
          (this.getUser?.role === 'Moderator' || this.getUser?.role === 'Admin') && this.getEsgId.status === 'Accepted'
      )
    },

    shouldDisabled() {
      let empty = this.fieldEsg.find(prop => !this.newEsg[prop])
      return empty !== undefined
    },

    fieldEsg() {
      return [
        'taxonomyType',
        'lat',
        'lng',
        'description',
        'name',
        'esgCategoryId',
        'esgTypeId',
        'esgSubtypeId',
        'efficiencyPlan',
        'efficiencyFact',
        'investmentAmount',
        'efficiencyPlanMeasure',
        'efficiencyFactMeasure',
        'investmentMeasure'
      ]
    },

    getNickname() {
      return this.getEsgId.createdBy?.role === 'Inspector' || this.getEsgId.createdBy?.role === 'Lawyer' ? this.getUserName(this.getEsgId.createdBy?.lastName, this.getEsgId.createdBy?.firstName, this.getEsgId.createdBy?.middleName) : '' ||
      this.getEsgId.createdBy?.role === 'Company' ? this.getEsgId.createdBy?.companyName : '' ||
      this.getEsgId.createdBy?.role === 'Government' ? this.getEsgId.createdBy?.companyName : this.getEsgId.createdBy?.role === 'Active' ? this.getEsgId.createdBy?.login : ''
    },

    savedDraftDisabled () {
      return this.newEsg.lat && this.newEsg.name ? true : false;
    }
  },

  watch: {
    'newEsg.lat'() {
      this.isValidLocation = false
    }
  },

  methods: {
    getEsgStatus,
    getFullDate,
    getUserAvatar,
    getUserRole,
    ...mapActions([
      'esgId',
      'addEsg',
      'closeEsg',
      'deleteEsg',
      'editEsg',
      'acceptEsg',
      'rejectEsg'
    ]),
    ...mapMutations(['setEsgId']),
    feelAllTypes (data) {
      this.newEsg.esgCategoryId = data.category?.id ? +data.category?.id : null
      this.newEsg.esgTypeId = data.type?.id ? +data.type?.id : null
      this.newEsg.esgSubtypeId = data.subtype?.id ? +data.subtype?.id : null
      this.newEsg.taxonomyType = data.typeTaxes?.value ? data.typeTaxes?.value : null
      this.newEsg.name = data.projectName ? data.projectName : null
      this.newEsg.description = data.description ? data.description : null
      this.newEsg.files = data.documentFile ? data.documentFile : null
    },

    sendMapInfo(data) {
      this.newEsg.lat = +data.lat
      this.newEsg.lng = +data.lng
      this.isDisabled = true
    },

    addNewEsg (status) {
      this.newEsg.status = status
      if(this.getEsgId.status === 'Draft') {
        this.newEsg.id = this.getEsgId.id
        this.editEsg(this.newEsg).then(() => {
          this.$router.push('/esg')
        })
      } else {
        this.addEsg(this.newEsg).then(() => {
          this.$router.push('/esg')
        })
      }
    },

    validateForm() {
      this.$refs.form?.$v?.info?.$touch()
      if(this.newEsg.lat === 0 && this.newEsg.lng === 0) {
        this.isValidLocation = true
      } else {
        this.isValidLocation = false
      }
    },

    closeModeratorEsg () {
      this.visibleLoader = true
      this.closeEsg({id: this.getEsgId.id}).then(() => {
        this.esgId({id: this.$route.params.id}).then(() => {
          this.visibleLoader = false
        })
      })
    },

    accept() {
      this.visibleLoader = true
      this.acceptEsg({id: this.getEsgId.id}).then(() => {
        this.esgId({id: this.$route.params.id}).then(() => {
          this.visibleLoader = false
        })
      })
    },

    reject() {
      this.visibleLoader = true
      this.rejectEsg({id: this.getEsgId.id}).then(() => {
        this.esgId({id: this.$route.params.id}).then(() => {
          this.visibleLoader = false
        })
      })
    },

    deleteEsgItem () {
      this.showDeleteAlert = false
      this.deleteEsg({id: this.getEsgId.id}).then(() => {
        this.$router.push('/esg')
      })
    },

    editDraftEsg (status) {
      this.newEsg.status = status
      this.newEsg.id = this.getEsgId.id
      this.editEsg(this.newEsg).then(() => {
        this.$router.push('/esg')
      })
    },

    getUserName(name, surname, middlename) {
      if(name && surname && middlename) {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.' + middlename.split('')[0].toUpperCase() + '.'
      }else {
        return name + ' ' + surname?.split('')[0].toUpperCase() + '.'
      }
    },

    sendTableInfo(data) {
      if(data.type === 'fact') {
        this.newEsg.efficiencyFact = data.description ? data.description : null
        this.newEsg.efficiencyFactMeasure = data.measure ? data.measure : null
        this.newEsg.efficiencyMeasure = data.measure ? data.measure : null
      } else if (data.type === 'plan') {
        this.newEsg.efficiencyPlan = data.description ? data.description : null
        this.newEsg.efficiencyPlanMeasure = data.measure ? data.measure : null
        this.newEsg.efficiencyMeasure = data.measure ? data.measure : null
      } else if (data.type === 'invest') {
        this.newEsg.investmentAmount = data.description ? data.description : null
        this.newEsg.investmentMeasure = data.measure ? data.measure : null
      }
    }
  },

  mounted () {
    if(this.$route.path !== '/add-esg') {
      this.esgId({id: this.$route.params.id}).then((res) => {
        this.visibleLoader = false
        if (res.status === 'Draft') {
          this.newEsg.description = res.description
          this.newEsg.efficiencyPlan = res.efficiencyPlan
          this.newEsg.efficiencyFact = res.efficiencyFact
          this.newEsg.investmentAmount = res.investmentAmount
          this.newEsg.efficiencyMeasure = res.efficiencyMeasure
          this.newEsg.efficiencyPlanMeasure = res.efficiencyMeasure
          this.newEsg.efficiencyFactMeasure = res.efficiencyMeasure
          this.newEsg.investmentMeasure = res.investmentMeasure
          this.newEsg.esgCategoryId = +res.category?.id
          this.newEsg.esgTypeId = +res.type?.id
          this.newEsg.esgSubtypeId = +res.subtype?.id
          this.newEsg.taxonomyType = res.taxonomyType
          this.newEsg.name = res?.name
          this.newEsg.files = res?.files
        }
      })
    }else if (this.$route.path === '/add-esg') {
      this.visibleLoader = false
    }
  },

  destroyed() {
    this.setEsgId([])
  },
}
</script>

<style lang="scss" scoped>
.view-esg-content {
  background: #F5F5F5;
  padding: 120px 50px 50px 50px;
  min-height: 100vh;

  @media (max-width: 1200px) {
    padding: 99px 20px 50px 20px;
  }
}
.body {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-back {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #1B1A1F;
      font-family: 'Gotham Pro Medium';
      font-size: 20px;
      font-weight: 400;
      cursor: pointer;
    }

    &-action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;

      @media (max-width: 768px) {
        justify-content: space-between;
      }

      &-text {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #9A9A9A;
        white-space: nowrap;
        cursor: pointer;

        @media (max-width: 768px) {
          display: none;
        }

        &:hover {
          color: #E21F1F;
        }
      }

      &-close {
        max-width: 180px !important;
        min-width: 180px !important;

        @media (max-width: 768px) {
          height: 30px !important;
        }
      }

      &-icons {
        display: none;
        gap: 20px;

        @media (max-width: 768px) {
          display: flex;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px 0 rgba(153, 153, 153, 0.20);

    &__header {
      display: grid;
      align-items: center;
      gap: 30px;
      grid-template-columns: 1fr 1fr;
      padding: 30px 30px 26px 30px;

      @media (max-width: 768px) {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &-left {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .wrap {
          display: flex;
          flex-direction: column;
          gap: 20px;

          &-status {
            display: flex;
            align-items: center;
            gap: 5px;
            font-weight: 400;
            font-size: 12px;
            line-height: 11px;
            color: #1B1A1F;

            .chip {
              width: 10px;
              height: 10px;
              background: #CE2121;
              border-radius: 200px;
            }
          }
        }

        .time {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 10px;
          color: #9A9A9A;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }

      &-delete {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #9A9A9A !important;
        gap: 10px;
        cursor: pointer;
      }

      &-right {
        max-width: 630px;
        width: 100%;

        .user {
          display: flex;
          align-items: center;
          gap: 10px;
          background-color: #FFFFFF;
          padding: 5px 10px 5px 5px;
          border: 1px solid #D9D9D9;
          box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
          border-radius: 200px;

          &__avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
            border: 1px solid #CE2121;
          }

          &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 10px;

            &-nickname {
              font-weight: 400;
              font-size: 16px;
              line-height: 15px;
              color: #343432;
            }

            &-role {
              display: flex;
              align-items: center;
              gap: 5px;
              font-family: "Gotham Pro Regular";
              font-weight: 400;
              font-size: 12px;
              line-height: 11px;
              color: #9A9A9A;

              img {
                width: 10px;
                height: 10px;
              }

              span {
                font-family: "Gotham Pro Regular";
                display: flex;
                align-items: center;
                gap: 5px;

                &:before {
                  content: '';
                  width: 4px;
                  height: 4px;
                  background: #BFBDBD;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
    }

    &__main {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      padding: 30px 25px 30px 30px;
      border-top: 1px solid #F5F5F5;

      @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        padding: 20px 10px;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    width: 100%;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    ::v-deep .button {
      max-width: 290px;
      width: 100%;
    }

    &-action {
      position: relative;
      max-width: 290px;
      width: 100%;

      &-backdrop {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: not-allowed;
        border-radius: 20px;
      }
    }
  }
}
::v-deep .gm-svpc {
  display: none;
}
</style>
